<template>
  <el-form :model="smplForm" ref="comList" :disabled="isShow" size="mini">
    <el-table
      :row-style="({ row, rowIndex }) => (row.index = rowIndex)"
      :data="smplForm.smpl_combination_List"
      border
      max-height="500"
      @selection-change="handleSelectionChange1"
      class="el-table-two"
    >
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">裁片名称</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'smpl_combination_List.' + scope.$index + '.mtrlNameSum'" :rules="[{ required: true }]">
            <el-tooltip
              class="item"
              effect="light"
              :content="smplForm.smpl_combination_List[scope.$index].mtrlNameSum"
              :disabled="!smplForm.smpl_combination_List[scope.$index].mtrlNameSum"
              placement="top-start"
            >
              <el-input
                @blur="$emit('blurIndex', scope.$index)"
                v-model="smplForm.smpl_combination_List[scope.$index].mtrlNameSum"
                show-word-limit
                placeholder="暂无裁片名称"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">工艺动作</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'smpl_combination_List.' + scope.$index + '.craftActionIds'" :rules="[{ required: true }]">
            <el-select
              @change="semiprodChange(scope)"
              v-if="flag"
              v-model="smplForm.smpl_combination_List[scope.$index].craftActionIds"
              multiple
              placeholder="请选择"
            >
              <el-option v-for="item in optActionList" :key="item.id" :label="item.param1" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="工艺备注">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'smpl_combination_List.' + scope.$index + '.mtrl_name'">
            <el-tooltip
              class="item"
              effect="light"
              :content="smplForm.smpl_combination_List[scope.$index].craftRemark"
              :disabled="!smplForm.smpl_combination_List[scope.$index].craftRemark"
              placement="top-start"
            >
              <el-input
                autosize
                :content="smplForm.smpl_combination_List[scope.$index].craftRemark"
                type="textarea"
                v-model="smplForm.smpl_combination_List[scope.$index].craftRemark"
                show-word-limit
                placeholder="暂无工艺备注"
              ></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="部位编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'smpl_combination_List.' + scope.$index + '.mtrlNo'">
            <el-input
              v-model="smplForm.smpl_combination_List[scope.$index].mtrlNo"
              @input="
                smplForm.smpl_combination_List[scope.$index].mtrlNo = helper.keepEngNum2T(
                  smplForm.smpl_combination_List[scope.$index].mtrlNo
                )
              "
              maxlength="18"
              show-word-limit
              placeholder="暂无部位编号"
              :disabled="true"
            >
              <template slot="prepend" class="vd_smplNo">{{ smplForm.smpl_no }}#</template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">生成部位</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'smpl_combination_List.' + scope.$index + '.createNewMtrl'" :rules="[{ required: true }]">
            <el-input
              @change="comNoChange(scope)"
              v-model="smplForm.smpl_combination_List[scope.$index].createNewMtrl"
              maxlength="30"
              show-word-limit
              placeholder="暂无生成部位"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>
<script>
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { cloneDeep } from 'lodash';
import { smplAPI } from '@api/modules/smpl';
export default {
  name: 'SmplEditCombinationChild',
  props: {
    smplForm: {
      type: Object,
      required: true
    },
    isShow: Boolean
  },
  data() {
    return {
      selectionsList: [],
      crtfTypeGroupOpt: [],
      flag: true,
      semiprodOption: [],
      tableData: [],
      smpl_semiprod_list: [],
      optActionList: []
    };
  },
  watch: {
    'smplForm.smpl_cut_list'(newValue, oldValue) {
      console.log(newValue);
      this.getSemiprodOptions();
    }
  },
  mounted() {
    this.getActionType();
  },
  methods: {
    // 保存组合信息验证
    submitCom() {
      this.$refs['comList'].validate(valid => {
        if (valid) {
          this.saveCombination();
        } else {
          console.log('error submit!');
          return false;
        }
      });
    },
    //保存组合信息
    saveCombination() {
      for (let i = 0; i < this.smplForm.smpl_combination_List.length; i++) {
        this.smplForm.smpl_combination_List[i].craftActionIds = this.smplForm.smpl_combination_List[i].craftActionIds.join(',');
      }
      for (let i = 0; i < this.smplForm.smpl_combination_List.length; i++) {
        this.smplForm.smpl_combination_List[i].mtrlNo = this.smplForm.smpl_no + '#' + this.smplForm.smpl_combination_List[i].mtrlNo;
      }
      post(smplAPI.addCreateSample, this.smplForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          // this.getsmplInfo(0);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        }
        this.$emit('refresh');
      });
    },
    // 生成部件重复
    createNewMtrl(scope) {
      let mtrlFlag = 0;
      for (let i = 0; i < this.smplForm.smpl_combination_List.length; i++) {
        if (this.smplForm.smpl_combination_List[i].createNewMtrl === scope.row.createNewMtrl) {
          mtrlFlag += 1;
        }
      }
      if (mtrlFlag > 1) {
        scope.row.createNewMtrl = null;
        this.$message.error('生成部件重复，请重新填写！');
      }
    },
    initData() {
      // this.getCrtfType()
    },
    getSemiprodOptions() {
      let semiprodOptions = Array.from(this.smplForm.smpl_cut_list, ({ mtrl_name, smpl_mtrl_id }) => ({
        mtrl_no: '' + mtrl_name,
        smpl_mtrl_id: smpl_mtrl_id
      }));
      this.semiprodOption = [];
      this.semiprodOption = semiprodOptions;
    },
    // 获取工艺动作
    getActionType() {
      get(optnAPI.getAllOptAction, { perm_id: 10016 }).then(res => {
        this.optActionList = res.data.data;
      });
    },
    semiprodChange(val, index) {
      this.smpl_semiprod_list[index]['smpl_parent_ids'] = val.join(',');
    },
    //组合信息选择
    handleSelectionChange1(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionCombination', this.selectionsList);
    },
    // 部位名称重复
    comNoChange(scope) {
      if (scope.row.createNewMtrl === '') {
        this.$message.error('部位名称不能为空，请重新填写！');
      }
      let comFlag = 0;
      for (let i = 0; i < this.smplForm.smpl_combination_List.length; i++) {
        if (this.smplForm.smpl_combination_List[i].createNewMtrl === scope.row.createNewMtrl) {
          comFlag += 1;
        }
      }
      if (comFlag > 1) {
        scope.row.createNewMtrl = null;
        this.$message.error('部位名称重复，请重新填写！');
      } else {
        this.$emit('comNoChange', scope);
      }
    }
  }
};
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl {
  margin-left: 5px;
}
.vd_wid100 {
  width: 100%;
}
.el-input-group__prepend {
  font-size: 16px;
  color: #000000;
}
</style>
