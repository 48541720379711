<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status == 0 ? true : false"
                >编辑</el-button
              >
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form ref="emcdRef" :model="emcdForm" label-width="100px" :rules="emcdRules" :disabled="isShow">
            <el-row>
              <el-col :md="12">
                <el-form-item label="贴布片数：" prop="rfid_patch_num">
                  <el-input
                    maxlength="9"
                    show-word-limit
                    v-model="emcdForm.rfid_patch_num"
                    @input="emcdForm.rfid_patch_num = helper.keepTNum(emcdForm.rfid_patch_num)"
                    size="small"
                  >
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <el-form-item label="绣片数量：" prop="rfid_emcd_num">
                  <el-input
                    maxlength="9"
                    show-word-limit
                    v-model="emcdForm.rfid_emcd_num"
                    size="small"
                    @input="emcdForm.rfid_emcd_num = helper.keepTNum(emcdForm.rfid_emcd_num)"
                  >
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :md="12">
                <el-form-item label="制卡针数：" prop="rfid_emcd_phr">
                  <el-input
                    maxlength="9"
                    show-word-limit
                    v-model="emcdForm.rfid_emcd_phr"
                    size="small"
                    @input="emcdForm.rfid_emcd_phr = helper.keepTNum(emcdForm.rfid_emcd_phr)"
                  >
                    <template slot="append">针</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <el-form-item label="制卡个数：" prop="rfid_emcd_numb">
                  <el-input
                    maxlength="9"
                    show-word-limit
                    v-model="emcdForm.rfid_emcd_numb"
                    size="small"
                    @input="emcdForm.rfid_emcd_numb = helper.keepTNum(emcdForm.rfid_emcd_numb)"
                  >
                    <template slot="append">个</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { spocAPI } from '@api/modules/spoc';
export default {
  data() {
    return {
      emcdForm: {
        rfid_emcd_id: '',
        rfid_id: '',

        stff_id: '',
        stff_name: '',
        user_id: '',
        user_rfid_no: '',
        rfid_patch_num: '',
        rfid_emcd_phr: '',
        rfid_emcd_num: '',
        rfid_emcd_numb: ''
      },
      isShow: true,
      emcdRules: {
        rfid_emcd_phr: [
          { required: true, message: '请填写制卡针数：', trigger: true },
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }
        ],
        rfid_patch_num: [
          { required: true, message: '请填写贴补片数：', trigger: true },
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }
        ],

        rfid_emcd_num: [
          { required: true, message: '请填写绣片数量：', trigger: true },
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }
        ],
        rfid_emcd_numb: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }]
      },
      originalEmcd: {
        rfid_emcd_phr: '',
        rfid_patch_num: '',
        rfid_emcd_num: '',
        rfid_emcd_numb: ''
      }
    };
  },
  props: {
    prop: Object,
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean
  },
  methods: {
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    submit() {
      this.$refs['emcdRef'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将保存修改, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(res => {
              if (!this.emcdForm.rfid_id) {
                this.emcdForm.rfid_id = this.rfid_Info.rfid_id;
                this.emcdForm.user_id = this.rfid_Info.user_id;
                this.emcdForm.stff_name = this.rfid_Info.stff_name;
                this.emcdForm.stff_id = this.rfid_Info.stff_id;
              }
              post(spocAPI.edit_emcd, this.emcdForm)
                .then(res => {
                  if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    this.originalEmcd.rfid_patch_num = this.emcdForm.rfid_patch_num;
                    this.originalEmcd.rfid_emcd_num = this.emcdForm.rfid_emcd_num;
                    this.originalEmcd.rfid_emcd_phr = this.emcdForm.rfid_emcd_phr;
                    this.originalEmcd.rfid_emcd_numb = this.emcdForm.rfid_emcd_numb;
                  } else {
                    this.$message.error(res.data.msg);
                  }
                  this.$emit('del');
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });

              this.isShow = true;
            })
            .catch(res => {});
        }
      });
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.emcdForm.rfid_patch_num = this.originalEmcd.rfid_patch_num;
          this.emcdForm.rfid_emcd_num = this.originalEmcd.rfid_emcd_num;
          this.emcdForm.rfid_emcd_phr = this.originalEmcd.rfid_emcd_phr;
          this.emcdForm.rfid_emcd_numb = this.originalEmcd.rfid_emcd_numb;
        })
        .catch(res => {});
    }
  },
  mounted() {
    this.$EventBus.$on('emcdEvent', res => {
      this.emcdForm.rfid_emcd_id = res.rfid_emcd_id;
      this.emcdForm.rfid_emcd_phr = res.rfid_emcd_phr;
      this.emcdForm.rfid_id = res.rfid_id;
      this.emcdForm.rfid_patch_num = res.rfid_patch_num;
      this.emcdForm.stff_id = res.stff_id;
      this.emcdForm.stff_name = res.stff_name;
      this.emcdForm.user_id = res.user_id;
      this.emcdForm.user_rfid_no = res.user_rfid_no;
      this.emcdForm.rfid_emcd_num = res.rfid_emcd_num;
      this.emcdForm.rfid_emcd_numb = res.rfid_emcd_numb;

      this.originalEmcd.rfid_patch_num = res.rfid_patch_num;
      this.originalEmcd.rfid_emcd_num = res.rfid_emcd_num;
      this.originalEmcd.rfid_emcd_phr = res.rfid_emcd_phr;
      this.originalEmcd.rfid_emcd_numb = res.rfid_emcd_numb;
    });
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
