<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status == 0 ? true : false"
                >编辑</el-button
              >
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form :model="Form" ref="manuRef" label-width="110px" :disabled="isShow" :rules="manuRules" size="small">
            <!-- 皮克 -->
            <!-- <el-row>
							<el-form-item label="皮壳重量：" prop="smpl_skin_weight">
								<el-col :md="8">
									<el-input
										maxlength="11"
										show-word-limit
										v-model="Form.smpl_skin_weight"
										@input="
											Form.smpl_skin_weight = helper.keepTNum1(
												Form.smpl_skin_weight
											)
										"
									>
										<template slot="append">克</template>
									</el-input>
								</el-col>
							</el-form-item>
						</el-row> -->
            <!-- 填充物 -->
            <!-- <el-row>
							<el-form-item label="填充物重量：" prop="smpl_fill_weight">
								<el-col :md="8">
									<el-input
										maxlength="11"
										show-word-limit
										v-model="Form.smpl_fill_weight"
										@input="
											Form.smpl_fill_weight = helper.keepTNum1(
												Form.smpl_fill_weight
											)
										"
									>
										<template slot="append">克</template>
									</el-input>
								</el-col>
							</el-form-item>
						</el-row> -->
            <!-- 产品总重 -->
            <!-- <el-row>
							<el-form-item label="产品总重：" prop="smpl_weight">
								<el-col :md="8">
									<el-input
										maxlength="11"
										show-word-limit
										v-model="Form.smpl_weight"
										@input="Form.smpl_weight = helper.keepTNum1(Form.smpl_weight)"
									>
										<template slot="append">克</template>
									</el-input>
								</el-col>
							</el-form-item>
						</el-row> -->
            <!-- 剪线 -->
            <el-row>
              <el-form-item label="减线时间：">
                <el-col :md="8">
                  <el-form-item prop="cutt_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.cutt_phr_points"
                      @input="Form.cutt_phr_points = helper.keepTNum(Form.cutt_phr_points)"
                      @change="sett"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="cutt_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.cutt_phr_seconds"
                      @change="sett"
                      @input="Form.cutt_phr_seconds = helper.keepTNum(Form.cutt_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 翻皮 -->
            <el-row>
              <el-form-item label="翻皮时间：">
                <el-col :md="8">
                  <el-form-item prop="skin_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.skin_phr_points"
                      @change="sett"
                      @input="Form.skin_phr_points = helper.keepTNum(Form.skin_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="skin_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.skin_phr_seconds"
                      @change="sett"
                      @input="Form.skin_phr_seconds = helper.keepTNum(Form.skin_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 充棉 -->
            <el-row>
              <el-form-item label="充棉时间：">
                <el-col :md="8">
                  <el-form-item prop="cott_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.cott_phr_points"
                      @change="sett"
                      @input="Form.cott_phr_points = helper.keepTNum(Form.cott_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="cott_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.cott_phr_seconds"
                      @change="sett"
                      @input="Form.cott_phr_seconds = helper.keepTNum(Form.cott_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 针工 -->
            <el-row>
              <el-form-item label="针工时间：">
                <el-col :md="8">
                  <el-form-item prop="nedl_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.nedl_phr_points"
                      @change="sett"
                      @input="Form.nedl_phr_points = helper.keepTNum(Form.nedl_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="nedl_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.nedl_phr_seconds"
                      @change="sett"
                      @input="Form.nedl_phr_seconds = helper.keepTNum(Form.nedl_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 整理 -->
            <el-row>
              <el-form-item label="整理时间：">
                <el-col :md="8">
                  <el-form-item prop="sort_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.sort_phr_points"
                      @change="sett"
                      @input="Form.sort_phr_points = helper.keepTNum(Form.sort_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="sort_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.sort_phr_seconds"
                      @change="sett"
                      @input="Form.sort_phr_seconds = helper.keepTNum(Form.sort_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 其他 -->
            <el-row>
              <el-form-item label="其他时间：">
                <el-col :md="8">
                  <el-form-item prop="othe_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.othe_phr_points"
                      @change="sett"
                      @input="Form.othe_phr_points = helper.keepTNum(Form.othe_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="othe_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.othe_phr_seconds"
                      @change="sett"
                      @input="Form.othe_phr_seconds = helper.keepTNum(Form.othe_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input></el-form-item
                  >
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 包装 -->
            <el-row>
              <el-form-item label="包装时间：">
                <el-col :md="8">
                  <el-form-item prop="pack_phr_points">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="Form.pack_phr_points"
                      @change="sett"
                      @input="Form.pack_phr_points = helper.keepTNum(Form.pack_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="pack_phr_seconds">
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="Form.pack_phr_seconds"
                      @change="sett"
                      @input="Form.pack_phr_seconds = helper.keepTNum(Form.pack_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 总用时 -->
            <el-row>
              <el-form-item label="手工总时间：" prop="rfid_manu_phr">
                <el-col :md="8">
                  <el-form-item prop="rfid_manu_phr_points">
                    <el-input
                      :disabled="true"
                      maxlength="6"
                      show-word-limit
                      v-model="Form.rfid_manu_phr_points"
                      @input="Form.rfid_manu_phr_points = helper.keepTNum(Form.rfid_manu_phr_points)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="rfid_manu_phr_seconds">
                    <el-input
                      :disabled="true"
                      maxlength="2"
                      show-word-limit
                      v-model="Form.rfid_manu_phr_seconds"
                      @input="Form.rfid_manu_phr_seconds = helper.keepTNum(Form.rfid_manu_phr_seconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!-- 备注 -->
            <el-row>
              <el-form-item label="备注：" prop="remark">
                <el-col :md="16">
                  <el-input maxlength="200" show-word-limit type="textarea" v-model="Form.remark"> </el-input>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { spocAPI } from '@api/modules/spoc';
export default {
  data() {
    return {
      Form: {
        cutt_phr_points: '',
        cutt_phr_seconds: '',
        skin_phr_points: '',
        skin_phr_seconds: '',
        cott_phr_points: '',
        cott_phr_seconds: '',
        nedl_phr_points: '',
        nedl_phr_seconds: '',
        sort_phr_points: '',
        sort_phr_seconds: '',

        othe_phr_points: '',
        othe_phr_seconds: '',

        pack_phr_points: '',
        pack_phr_seconds: '',

        rfid_manu_phr_points: '',
        rfid_manu_phr_seconds: '',
        smpl_weight: '',
        smpl_skin_weight: '',
        smpl_fill_weight: '',
        remark: ''
      },
      originalManu: {
        cutt_phr_points: '',
        cutt_phr_seconds: '',
        skin_phr_points: '',
        skin_phr_seconds: '',
        cott_phr_points: '',
        cott_phr_seconds: '',
        nedl_phr_points: '',
        nedl_phr_seconds: '',
        sort_phr_points: '',
        sort_phr_seconds: '',

        othe_phr_points: '',
        othe_phr_seconds: '',

        pack_phr_points: '',
        pack_phr_seconds: '',

        rfid_manu_phr_points: '',
        rfid_manu_phr_seconds: '',
        smpl_weight: '',
        smpl_skin_weight: '',
        smpl_fill_weight: '',
        remark: ''
      },
      isShow: true,
      manuRules: {
        smpl_skin_weight: [
          {
            required: true,
            message: '请填写皮壳重量',
            trigger: 'blur'
          },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ],
        smpl_fill_weight: [
          {
            required: true,
            message: '请填写填充物重量',
            trigger: 'blur'
          },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ],
        smpl_weight: [
          {
            required: true,
            message: '请填写填产品总重',
            trigger: 'blur'
          },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ],
        cutt_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        cutt_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        skin_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        skin_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        cott_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        cott_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        nedl_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        nedl_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        sort_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        sort_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        othe_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        othe_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        pack_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        pack_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        rfid_manu_phr_points: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        rfid_manu_phr_seconds: [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'change' }]
      },
      prop: {}
    };
  },
  methods: {
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    submit() {
      this.$refs['manuRef'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将保存修改, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(res => {
              const editBody = {};
              editBody.user_id = this.prop.user_id;
              editBody.stff_id = this.prop.stff_id;
              editBody.stff_name = this.prop.stff_name;
              editBody.user_rfid_no = this.prop.user_rfid_no;
              editBody.rfid_id = this.prop.rfid_id;

              editBody.nedl_phr = this.Form.nedl_phr_points * 60 + (this.Form.nedl_phr_seconds - 0);

              editBody.skin_phr = this.Form.skin_phr_points * 60 + (this.Form.skin_phr_seconds - 0);

              editBody.cutt_phr = this.Form.cutt_phr_points * 60 + (this.Form.cutt_phr_seconds - 0);

              editBody.cott_phr = this.Form.cott_phr_points * 60 + (this.Form.cott_phr_seconds - 0);

              editBody.sort_phr = this.Form.sort_phr_points * 60 + (this.Form.sort_phr_seconds - 0);

              editBody.othe_phr = this.Form.othe_phr_points * 60 + (this.Form.othe_phr_seconds - 0);

              editBody.pack_phr = this.Form.pack_phr_points * 60 + (this.Form.pack_phr_seconds - 0);
              editBody.rfid_manu_phr = this.Form.rfid_manu_phr_points * 60 + (this.Form.rfid_manu_phr_seconds - 0);
              editBody.remark = this.Form.remark;
              editBody.smpl_weight = this.Form.smpl_weight;
              editBody.smpl_skin_weight = this.Form.smpl_skin_weight;
              editBody.smpl_fill_weight = this.Form.smpl_fill_weight;

              if (!editBody.rfid_id) {
                editBody.rfid_id = this.rfid_Info.rfid_id;
                editBody.user_id = this.rfid_Info.user_id;
                editBody.stff_name = this.rfid_Info.stff_name;
                editBody.stff_id = this.rfid_Info.stff_id;
              }
              post(spocAPI.edit_manu, editBody)
                .then(res => {
                  if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                  } else {
                    this.$message.error(res.data.msg);
                  }
                  this.$emit('del');
                  this.originalManu.smpl_skin_weight = this.Form.smpl_skin_weight;
                  this.originalManu.smpl_weight = this.Form.smpl_weight;
                  this.originalManu.cutt_phr_points = this.Form.cutt_phr_points;
                  this.originalManu.cutt_phr_seconds = this.Form.cutt_phr_seconds;
                  this.originalManu.skin_phr_points = this.Form.skin_phr_points;
                  this.originalManu.skin_phr_seconds = this.Form.skin_phr_seconds;
                  this.originalManu.cott_phr_points = this.Form.cott_phr_points;
                  this.originalManu.cott_phr_seconds = this.Form.cott_phr_seconds;
                  this.originalManu.nedl_phr_points = this.Form.nedl_phr_points;
                  this.originalManu.nedl_phr_seconds = this.Form.nedl_phr_seconds;
                  this.originalManu.sort_phr_points = this.Form.sort_phr_points;
                  this.originalManu.sort_phr_seconds = this.Form.sort_phr_seconds;
                  this.originalManu.othe_phr_points = this.Form.othe_phr_points;
                  this.originalManu.othe_phr_seconds = this.Form.othe_phr_seconds;
                  this.originalManu.pack_phr_points = this.Form.pack_phr_points;
                  this.originalManu.pack_phr_seconds = this.Form.pack_phr_seconds;
                  this.originalManu.rfid_manu_phr_points = this.Form.rfid_manu_phr_points;
                  this.originalManu.rfid_manu_phr_seconds = this.Form.rfid_manu_phr_seconds;

                  this.originalManu.smpl_fill_weight = this.Form.smpl_fill_weight;
                  this.originalManu.remark = this.Form.remark + '';
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });

              this.isShow = true;
            })
            .catch(res => {});
        }
      });
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.Form.smpl_skin_weight = this.originalManu.smpl_skin_weight;
          this.Form.smpl_weight = this.originalManu.smpl_weight;
          this.Form.cutt_phr_points = this.originalManu.cutt_phr_points;
          this.Form.cutt_phr_seconds = this.originalManu.cutt_phr_seconds;
          this.Form.skin_phr_points = this.originalManu.skin_phr_points;
          this.Form.skin_phr_seconds = this.originalManu.skin_phr_seconds;
          this.Form.cott_phr_points = this.originalManu.cott_phr_points;
          this.Form.cott_phr_seconds = this.originalManu.cott_phr_seconds;
          this.Form.nedl_phr_points = this.originalManu.nedl_phr_points;
          this.Form.nedl_phr_seconds = this.originalManu.nedl_phr_seconds;
          this.Form.sort_phr_points = this.originalManu.sort_phr_points;
          this.Form.sort_phr_seconds = this.originalManu.sort_phr_seconds;
          this.Form.othe_phr_points = this.originalManu.othe_phr_points;
          this.Form.othe_phr_seconds = this.originalManu.othe_phr_seconds;
          this.Form.pack_phr_points = this.originalManu.pack_phr_points;
          this.Form.pack_phr_seconds = this.originalManu.pack_phr_seconds;
          this.Form.rfid_manu_phr_points = this.originalManu.rfid_manu_phr_points;
          this.Form.rfid_manu_phr_seconds = this.originalManu.rfid_manu_phr_seconds;

          this.Form.smpl_fill_weight = this.originalManu.smpl_fill_weight;
          this.Form.remark = this.originalManu.remark + '';
        })
        .catch(res => {});
    },
    sett() {
      let form = {};
      form.cutt_phr = this.Form.cutt_phr_points * 60 - 0 + (this.Form.cutt_phr_seconds - 0);

      form.skin_phr = this.Form.skin_phr_points * 60 - 0 + (this.Form.skin_phr_seconds - 0);

      form.cott_phr = this.Form.cott_phr_points * 60 - 0 + (this.Form.cott_phr_seconds - 0); //减线时间

      form.nedl_phr = this.Form.nedl_phr_points * 60 - 0 + (this.Form.nedl_phr_seconds - 0); //充棉时间 翻皮壳时间

      form.sort_phr = this.Form.sort_phr_points * 60 - 0 + (this.Form.sort_phr_seconds - 0); //整理时间

      form.othe_phr = this.Form.othe_phr_points * 60 - 0 + (this.Form.othe_phr_seconds - 0); //其他时间

      form.pack_phr = this.Form.pack_phr_points * 60 - 0 + (this.Form.pack_phr_seconds - 0); //包装时间
      form.rfid_manu_phr = form.nedl_phr + form.cutt_phr + form.cott_phr + form.sort_phr + form.othe_phr + form.pack_phr + form.skin_phr;
      if (isNaN(form.rfid_manu_phr)) {
        this.Form.rfid_manu_phr_points = '';
        this.Form.rfid_manu_phr_seconds = '';
      } else {
        this.Form.rfid_manu_phr_points = parseInt(form.rfid_manu_phr / 60) == 0 ? '' : parseInt(form.rfid_manu_phr / 60);

        this.Form.rfid_manu_phr_seconds = form.rfid_manu_phr % 60 == 0 ? '' : form.rfid_manu_phr % 60;
      }
    }
  },
  mounted() {
    this.$EventBus.$on('manuEvent', res => {
      this.prop.user_rfid_no = res.user_rfid_no;
      this.prop.user_id = res.user_id;
      this.prop.stff_id = res.stff_id;
      this.prop.stff_name = res.stff_name;
      this.prop.rfid_id = res.rfid_id;
      this.prop.user_jobs = res.user_jobs;

      //减线
      this.Form.cutt_phr_points = parseInt(res.cutt_phr / 60);
      this.Form.cutt_phr_seconds = res.cutt_phr % 60;
      //翻皮
      this.Form.skin_phr_points = parseInt(res.skin_phr / 60);
      this.Form.skin_phr_seconds = res.skin_phr % 60;
      // 充棉
      this.Form.cott_phr_points = parseInt(res.cott_phr / 60);
      this.Form.cott_phr_seconds = res.cott_phr % 60;
      //针工
      this.Form.nedl_phr_points = parseInt(res.nedl_phr / 60);
      this.Form.nedl_phr_seconds = res.nedl_phr % 60;
      //整理
      this.Form.sort_phr_points = parseInt(res.sort_phr / 60);
      this.Form.sort_phr_seconds = res.sort_phr % 60;
      //其他
      this.Form.othe_phr_points = parseInt(res.othe_phr / 60);
      this.Form.othe_phr_seconds = res.othe_phr % 60;
      //包装
      this.Form.pack_phr_points = parseInt(res.pack_phr / 60);
      this.Form.pack_phr_seconds = res.pack_phr % 60;
      //总用时
      this.Form.rfid_manu_phr_points = parseInt(res.rfid_manu_phr / 60);
      this.Form.rfid_manu_phr_seconds = res.rfid_manu_phr % 60;
      this.Form.remark = res.remark;
      this.Form.smpl_weight = res.smpl_weight;
      this.Form.smpl_skin_weight = res.smpl_skin_weight;
      this.Form.smpl_fill_weight = res.smpl_fill_weight;
      this.originalManu = JSON.parse(JSON.stringify(this.Form));
    });
  },
  props: {
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
