<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status == 0 ? true : false"
                >编辑</el-button
              >
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form ref="laseRef" :model="prop" label-width="120px" :rules="laseRules" :disabled="isShow" size="small">
            <el-row>
              <el-col :md="12">
                <el-form-item label="产品激光用量：" prop="rfid_lase_phr">
                  <el-input maxlength="9" show-word-limit :value="prop.rfid_lase_phr" @input="lasePhrChange">
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <el-form-item label="贴布激光用量：" prop="rfid_patch_lase">
                  <el-input maxlength="9" show-word-limit :value="prop.rfid_patch_lase" @input="lasePatchChange">
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { spocAPI } from '@api/modules/spoc';
export default {
  data() {
    return {
      laseForm: {},
      isShow: true,
      laseRules: {
        rfid_lase_phr: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请输入激光用量', trigger: 'blur' }
        ],
        rfid_patch_lase: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }]
      }
    };
  },
  props: {
    prop: Object,
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean,
    originalLase: Object
  },
  methods: {
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    submit() {
      this.$refs['laseRef'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将保存修改, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(res => {
              let editBody = {};
              editBody = JSON.parse(JSON.stringify(this.prop));
              if (!editBody.rfid_id) {
                editBody.rfid_id = this.rfid_Info.rfid_id;
                editBody.user_id = this.rfid_Info.user_id;
                editBody.stff_name = this.rfid_Info.stff_name;
                editBody.stff_id = this.rfid_Info.stff_id;
              }
              post(spocAPI.edit_lase, editBody)
                .then(res => {
                  if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    this.$emit('copyLase', this.prop);
                  } else {
                    this.$message.error(res.data.msg);
                  }

                  this.$emit('del');
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });

              this.isShow = true;
            })
            .catch(res => {});
        }
      });
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.prop.rfid_lase_phr = this.originalLase.rfid_lase_phr;
          this.prop.rfid_patch_lase = this.originalLase.rfid_patch_lase;
        })
        .catch(res => {});
    },
    lasePhrChange(val) {
      val = this.helper.keepTNum(val);
      this.$emit('lasePhrChange', val);
    },
    lasePatchChange(val) {
      val = this.helper.keepTNum(val);
      this.$emit('lasePatchChange', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
