<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status == 0 ? true : false"
                >编辑</el-button
              >
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form ref="maseRef" :model="maseForm" label-width="100px" :rules="maseRules" :disabled="isShow" size="small">
            <el-row :gutter="10">
              <el-col :md="18">
                <el-form-item label="缝纫片数：" label-width="100px">
                  <el-input
                    maxlength="9"
                    show-word-limit
                    v-model="maseForm.rfid_sew_slice"
                    @input="maseForm.rfid_sew_slice = helper.keepTNum(maseForm.rfid_sew_slice)"
                    ><template slot="append">片</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <el-form-item label="配件数量：" prop="rfid_cut_num">
                  <el-input maxlength="9" show-word-limit v-model="maseForm.rfid_cut_num" @input="cutNumChange">
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-form-item label="机缝用时：" :rules="{ required: true }">
                <el-col :md="8">
                  <el-form-item
                    prop="timePoints"
                    :rules="
                      maseForm.timePoints && maseForm.timeSeconds
                        ? maseRules.timePoints
                        : maseForm.timeSeconds
                        ? [{ required: false }]
                        : maseRules.timePoints
                    "
                  >
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="maseForm.timePoints"
                      @input="maseForm.timePoints = helper.keepTNum(maseForm.timePoints)"
                    >
                      <template slot="append">分</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item
                    prop="timeSeconds"
                    :rules="
                      maseForm.timePoints && maseForm.timeSeconds
                        ? maseRules.timeSeconds
                        : maseForm.timePoints
                        ? [{ required: false }]
                        : maseRules.timeSeconds
                    "
                  >
                    <el-input
                      maxlength="2"
                      show-word-limit
                      v-model="maseForm.timeSeconds"
                      @input="maseForm.timeSeconds = helper.keepTNum(maseForm.timeSeconds)"
                    >
                      <template slot="append">秒</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row :gutter="10">
              <el-col :md="18">
                <el-form-item
                  prop="remark"
                  label="备注："
                  label-width="100px"
                  :rules="{
                    required: true,
                    message: '请输入备注',
                    trigger: 'blur'
                  }"
                >
                  <el-input type="textarea" maxlength="200" show-word-limit :disabled="isShow" v-model="maseForm.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="文件：" label-width="100px">
                  <el-upload
                    :disabled="isShow"
                    class="upload-demo"
                    ref="uploadRef"
                    :action="uploadUrl"
                    :on-success="handAvatarSuccess"
                    :before-upload="beforeUploadVideo"
                    :limit="1"
                    :data="dataBody"
                    :on-remove="handRemove"
                  >
                    点击上传
                    <div slot="tip" class="el-upload__tip">提示：只能上传视频文件和图片文件，且不超过500MB</div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="file.docu_name">
              <el-col>
                <el-form-item label-width="100px">
                  <div>
                    <i class="el-icon-document"></i> 已保存:
                    {{ file.docu_name + '.' + file.docu_suffix }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item sizi="medium">
                  <div class="control">
                    <span v-if="url !== ''" @click="openVideo" type="success"> <i class="el-icon-video-play"></i> 在线观看 </span>
                    <el-link v-if="file.docu_suffix" type="success" @click="download()"> <i class="el-icon-download"></i> 下载</el-link>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
    <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" width="50%">
      <vue-core-video-player ref="vueVideo" :src="url"></vue-core-video-player>
    </el-dialog>
  </div>
</template>
<script src="./dist/vue-core-vide-player.umd.min.js"></script>
<script>
import { get, post } from '@api/request';
import { spocAPI } from '@api/modules/spoc';
import { docuAPI } from '@api/modules/docu';
import helper from '@assets/js/helper';
export default {
  data() {
    return {
      url: '',
      dialogVisible: false,
      maseForm: {
        timePoints: '',
        timeSeconds: '',
        remark: '',
        rfid_sew_slice: '',
        rfid_cut_num: ''
      },
      prop: {},
      file: {
        docu_id: '',
        docu_name: '',
        docu_suffix: '',
        docu_url: ''
      },
      docu_id: '',
      isShow: true,
      uploadUrl: helper.modePrepend(docuAPI.upload_video),
      dataBody: {
        docu_id: ''
      },
      maseRules: {
        timePoints: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }
        ],
        timeSeconds: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        rfid_cut_num: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }]
      },
      originalMase: {},
      imgTypeArr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng'
      ]
    };
  },
  methods: {
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    submit() {
      this.$refs['maseRef'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将保存修改, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(res => {
              const editBody = {};
              editBody.user_rfid_no = this.prop.user_rfid_no;
              editBody.user_id = this.prop.user_id;
              editBody.stff_id = this.prop.stff_id;
              editBody.stff_name = this.prop.stff_name;
              editBody.rfid_id = this.prop.rfid_id;
              editBody.remark = this.maseForm.remark;
              editBody.rfid_mase_time = this.maseForm.timePoints * 60 + (this.maseForm.timeSeconds - 0);
              editBody.docu_id = this.file.docu_id;
              editBody.rfid_sew_slice = this.maseForm.rfid_sew_slice;
              editBody.rfid_cut_num = this.maseForm.rfid_cut_num;
              if (!this.prop.rfid_id) {
                editBody.rfid_id = this.rfid_Info.rfid_id;
                editBody.user_id = this.rfid_Info.user_id;
                editBody.stff_name = this.rfid_Info.stff_name;
                editBody.stff_id = this.rfid_Info.stff_id;
              }
              post(spocAPI.edit_mase, editBody)
                .then(res => {
                  if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    this.originalMase.rfid_sew_slice = this.maseForm.rfid_sew_slice;
                    this.originalMase.rfid_cut_num = this.maseForm.rfid_cut_num;
                    this.originalMase.timePoints = this.maseForm.timePoints;
                    this.originalMase.timeSeconds = this.maseForm.timeSeconds;
                    this.originalMase.remark = this.maseForm.remark;

                    this.originalMase.docu_name = this.file.docu_name;
                    this.originalMase.docu_suffix = this.file.docu_suffix;
                    this.originalMase.docu_url = this.file.docu_url;
                  } else {
                    this.$message.error(res.data.msg);
                  }
                  this.$emit('del');
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });

              this.isShow = true;
            })
            .catch(res => {});
        }
      });
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.maseForm.rfid_cut_num = this.originalMase.rfid_cut_num;
          this.maseForm.rfid_sew_slice = this.originalMase.rfid_sew_slice;
          this.maseForm.timePoints = this.originalMase.timePoints;
          this.maseForm.timeSeconds = this.originalMase.timeSeconds;
          this.maseForm.remark = this.originalMase.remark + '';

          this.file.docu_name = this.originalMase.docu_name;
          this.file.docu_suffix = this.originalMase.docu_suffix;
          this.file.docu_url = this.originalMase.docu_url;
        })
        .catch(res => {});
    },
    handAvatarSuccess(response, file, fileList) {
      if (response.code == 0) {
        if (!this.imgTypeArr.indexOf(response.data.docu_suffix)) {
          if (!this.url) {
            this.url = helper.pVideoUrl(response.data.docu_url);
          }
        }
        if (!this.file.docu_id) {
          this.file.docu_id = response.data.docu_id;
          this.dataBody.docu_id = response.data.docu_id;
        }
        this.$message.success('上传成功');
      } else {
        this.$message.error('视频或图片上传失败，请重新上传！');
      }
    },
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 500;
      let fileName = file.name.substr(0, file.name.lastIndexOf('.'));
      if (fileName.length > 50) {
        this.$message.error('上传失败，文件名字过长! 最大长度50');
        return false;
      }
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/x-ms-wmv',
          'video/mwv',
          'video/rmvb',
          'image/png',
          'image/jpeg',
          'image/gif',
          'image/webp',
          'image/bmp',
          'image/tif'
        ].indexOf(file.type) == -1
      ) {
        this.$message.error('请上传正确的视频格式或图片格式');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传视频或图片大小不能超过500MB哦!');
        return false;
      }
    },
    handRemove(res) {
      this.file.docu_id = '';
      this.url = '';
    },
    //下载
    download() {
      let docuUrl = this.helper.megPath(this.file.docu_url);
      let docuName = this.file.docu_name + '.' + this.file.docu_suffix;
      this.helper.downloadItem(docuUrl, docuName);
    },

    cutNumChange(val) {
      val = this.helper.keepTNum(val);
      this.$emit('cutNumChange', val);
    },
    //视频暂停
    handleClose() {
      this.$refs.vueVideo.pause();
      this.dialogVisible = false;
    },
    openVideo() {
      if (!this.$refs.vueVideo) {
      } else {
        this.$refs.vueVideo.play();
      }
      this.dialogVisible = true;
    }
  },
  mounted() {
    this.$EventBus.$on('maseEvent', res => {
      let arr = ['mp4', 'ogg', 'flv', 'avi', 'wmv', 'mwv', 'rmvb'];
      if (arr.indexOf(res.docu_suffix) != -1) {
        this.url = helper.pVideoUrl(res.docu_url);
      }
      this.maseForm.rfid_sew_slice = res.rfid_sew_slice;
      this.maseForm.rfid_cut_num = res.rfid_cut_num;
      this.maseForm.timePoints = parseInt(res.rfid_mase_time / 60) == 0 ? '' : parseInt(res.rfid_mase_time / 60);
      this.maseForm.timeSeconds = res.rfid_mase_time % 60 == 0 ? '' : res.rfid_mase_time % 60;
      this.maseForm.remark = res.remark;
      this.file.docu_id = res.docu_id;
      this.file.docu_name = res.docu_name;
      this.file.docu_suffix = res.docu_suffix;
      this.file.docu_url = res.docu_url;
      this.prop.user_rfid_no = res.user_rfid_no;
      this.prop.user_id = res.user_id;
      this.prop.stff_id = res.stff_id;
      this.prop.stff_name = res.stff_name;
      this.prop.rfid_id = res.rfid_id;
      this.prop.user_jobs = res.user_jobs;

      this.dataBody.docu_id = res.docu_id || '';
      let obj = Object.assign({}, this.maseForm, this.file);
      this.originalMase = JSON.parse(JSON.stringify(obj));
    });
  },
  props: {
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
::v-deep .el-upload--text {
  width: 120px;
  height: 36px;
  text-align: center;
  line-height: 36px;
}
.control {
  span {
    border: 1px solid #2e2c4c;
    padding: 8px;
    border-radius: 20px;
    background-color: #2e2c4c;
    opacity: 0.8;
    color: #ffffff;
    cursor: pointer;
    margin-right: 20px;
  }
  .el-link {
    cursor: pointer;
    font-size: 16px;
  }
}
</style>
